/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import InputMask from "react-input-mask";
import { qrChargingInfoValidation } from "../../validations/schemas/QRChargingInfoSchema";
import NavBar from "../../components/NavBar/NavBar";
import { ediDateNoFormat, makeMoid, makePayEncryptData, todayNoFoarmat } from "../../utils/utils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import LoadingDiv from "../../components/Loading/LoadingDiv";
import { blue, button_text, stroke_gray2 } from "../../styles/colors";
import Footer from "../../components/Footer/Footer2";
import { BtnFullBlue2, BtnOutLineBlue } from "../../styles/themes";

function QRpayChargingInfoPage() {
  const location = useLocation();
  const [Loaded, setLoaded] = useState(false);
  const schema = qrChargingInfoValidation;
  const smartropay = window.smartropay;
  let [Phone, setPhone] = useState("");
  let [kwhPay, setKwhPay] = useState("20000");
  const [Moid, setMoid] = useState("");
  const [EdiDate, setEdiDate] = useState("");
  const params = new URLSearchParams(window.location.search);
  const result = params.get("result");
  const qrcode = window.location.pathname.split("/")[2];
  const [charger, setCharger] = useState({ price: null });
  const [Loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const type = navigator.userAgent;
    const script = document.createElement("script");

    if (type.indexOf("iPhone") > -1 || type.indexOf("Android") > -1) {
      script.src = "https://mpay.smartropay.co.kr/asset/js/SmartroPAY-1.0.min.js?version=" + todayNoFoarmat();
    } else {
      script.src = "https://pay.smartropay.co.kr/asset/js/SmartroPAY-1.0.min.js?version=" + todayNoFoarmat();
    }

    script.async = false;
    script.addEventListener("load", () => setLoaded(true));
    document.body.appendChild(script);

    if (result) {
      // 결제 결과 실패일 때
      let result_json = JSON.parse(result);
      setCharger({ price: result_json.price });

      // price
      alert(result_json.msg);
      // console.log(window.location.pathname);
      window.history.replaceState({}, null, window.location.pathname);
    } else {
      if (!location.state) {
        window.location.href = process.env.REACT_APP_URL + "QRpay/" + qrcode;
      }
      setCharger(location.state.charger);
      // console.log(charger);
    }
  }, []);

  useEffect(() => {
    if (!Loaded) {
      return;
    }
  }, [Loaded]);

  const goPay = () => {
    const type = navigator.userAgent;
    // mobile
    if (type.indexOf("iPhone") > -1 || type.indexOf("Android") > -1) {
      var input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("size", "100");
      input.setAttribute("name", "StopUrl");
      input.setAttribute("className", "input");
      input.setAttribute("value", process.env.REACT_APP_API_URL + "/qrPay/stop/" + qrcode);
      var form = document.getElementById("payform");
      form.appendChild(input);
    }
    smartropay.init({
      mode: process.env.REACT_APP_QR_MODE, // STG: 테스트, REAL: 운영
    });

    // 스마트로페이 결제요청
    // PC 연동시 아래와 같이 smartropay.payment 함수를 구현합니다.
    smartropay.payment({
      FormId: "payform", // 폼ID
      Callback: function (res) {
        setLoading(true);
        const type = navigator.userAgent;

        if (!type.indexOf("iPhone") > -1 || !type.indexOf("Android") > -1) {
          var approvalForm = document.approvalForm;
          approvalForm.qrcode.value = qrcode;
          approvalForm.Tid.value = res.Tid;
          approvalForm.TrAuthKey.value = res.TrAuthKey;
          approvalForm.phone.value = getValues("Phone");
          approvalForm.price.value = charger.price;
          // app
          approvalForm.action = process.env.REACT_APP_API_URL + "/qrPay/payment/" + qrcode + "," + getValues("Phone");
          approvalForm.submit();
        }
      },
    });
  };

  const onSubmit = (event) => {
    setMoid(makeMoid(event.kwhPay));
    setEdiDate(ediDateNoFormat());
    setPhone(event.Phone);
    setKwhPay(kwhPay);
    goPay();
  };

  const onChangeKwhPayHandler = (event) => {
    setKwhPay(event.target.id);
  };

  return (
    <>
      {Loading ? <LoadingDiv /> : null}
      <NavBar isNavBtnVisible={false} />
      <div css={QRpayWrap}>
        <Card>
          <Card.Body>
            <h1
              className="text-center"
              css={css`
                padding: 5%;
              `}
            >
              비회원 QR 결제
            </h1>
            <div
              css={css`
                padding: 0% 5% 5% 5%;
              `}
            >
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <label className="col-sm-12 col-form-label">
                    휴대폰 번호를 입력해 주세요 <span className="text-danger"> *</span>
                  </label>
                  <div className="col-sm-12">
                    <InputMask
                      className={"form-control " + (errors.Phone?.message ? "is-invalid" : "")}
                      placeholder="ex) 010-1234-5678"
                      mask="999-9999-9999"
                      maskChar=""
                      name="Phone"
                      {...register("Phone")}
                    />
                    {errors.Phone?.message && <div className="invalid-feedback">{errors.Phone?.message}</div>}
                  </div>
                </div>
                <div className="form-group row mb-3 mt-2">
                  <div className="col-sm-12">
                    <small className="form-text text-muted">
                      휴대전화 번호는 본인 확인을 위해 이용됩니다. 정확한 번호를 입력하지 않을 시, 서비스 이용에 불편을 겪을 수 있습니다.
                    </small>
                  </div>
                </div>
                <div className="row">
                  <label className="col-sm-12 col-form-label">
                    {" "}
                    충전 요금을 선택해 주세요 <span className="text-danger"> *</span>
                  </label>
                  <div className="col-12 form-group">
                    <Form.Check
                      type="radio"
                      label="500"
                      name="kwhPay"
                      value="500"
                      id="500"
                      {...register("kwhPay")}
                      onChange={onChangeKwhPayHandler}
                      isInvalid={errors.kwhPay}
                      className={errors.kwhPay?.message && "is-invalid"}
                    />
                  </div>
                  <div className="col-12 form-group">
                    <Form.Check
                      type="radio"
                      label="3,000"
                      name="kwhPay"
                      value="3000"
                      id="3000"
                      {...register("kwhPay")}
                      onChange={onChangeKwhPayHandler}
                      isInvalid={errors.kwhPay}
                      className={errors.kwhPay?.message && "is-invalid"}
                    />
                  </div>
                  <div className="col-12 form-group">
                    <Form.Check
                      type="radio"
                      label="5,000"
                      name="kwhPay"
                      value="5000"
                      id="5000"
                      {...register("kwhPay")}
                      onChange={onChangeKwhPayHandler}
                      isInvalid={errors.kwhPay}
                      className={errors.kwhPay?.message && "is-invalid"}
                    />
                  </div>
                  <div className="col-12 form-group">
                    <Form.Check
                      type="radio"
                      label="10,000"
                      name="kwhPay"
                      id="10000"
                      value="10000"
                      {...register("kwhPay")}
                      onChange={onChangeKwhPayHandler}
                      isInvalid={errors.kwhPay}
                      className={errors.kwhPay?.message && "is-invalid"}
                    />
                  </div>

                  <div className="col-12 form-group">
                    <Form.Check
                      type="radio"
                      label="2,0000"
                      name="kwhPay"
                      value="20000"
                      id="20000"
                      defaultChecked="checked"
                      {...register("kwhPay")}
                      onChange={onChangeKwhPayHandler}
                      isInvalid={errors.kwhPay}
                      className={errors.kwhPay?.message && "is-invalid"}
                    />
                  </div>
                  <div className="col-12 form-group">
                    <Form.Check
                      type="radio"
                      label="30,000"
                      name="kwhPay"
                      id="30000"
                      value="30000"
                      {...register("kwhPay")}
                      onChange={onChangeKwhPayHandler}
                      isInvalid={errors.kwhPay}
                      className={errors.kwhPay?.message && "is-invalid"}
                    />
                    {errors.kwhPay?.message && <div className="invalid-feedback">{errors.kwhPay?.message}</div>}
                  </div>
                </div>
                <div className="form-group row mt-2">
                  <div className="col-sm-12">
                    <small className="form-text text-muted">실제 충전량 만큼만 과금되며, 차액은 환불됩니다.</small>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <div
                      className="card bg-light"
                      css={css`
                        padding: 5px;
                        margin: 15px 0 15px 0;
                        border: 1px solid ${stroke_gray2} !important;
                      `}
                    >
                      <div
                        css={css`
                          color: ${blue};
                          font-size: 0.85em;
                          line-height: 1;
                        `}
                      >
                        <sub>예상 충전량</sub>
                      </div>
                      <div
                        className="card-body"
                        css={css`
                          padding: 1rem !important;
                        `}
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <div
                              css={css`
                                text-align: right !important;
                              `}
                            >
                              <span id="charging_kwh">{charger.price != null ? (kwhPay / parseInt(charger.price)).toFixed(2) : " - "}</span>
                              <span className="text-right">kWh</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <small className="form-text text-muted">예상 충전량은 시간대별 적용요금에 따라 변경되며, 실제와 차이가 있을 수 있습니다.</small>
                  </div>
                </div>
                <div
                  className="navigator mt-2"
                  css={css`
                    display: flex;
                    justify-content: space-between;
                  `}
                >
                  <div
                    css={css`
                      width: 100px;
                    `}
                  >
                    <Link to={-1} className="btn" css={BtnOutLineBlue}>
                      이전
                    </Link>
                  </div>
                  <div
                    css={css`
                      width: 100px;
                    `}
                  >
                    <button type="submit" css={BtnFullBlue2} className="btn next text-right active btn-go-pay">
                      결제하기
                    </button>
                  </div>
                </div>
              </Form>
              <Form id="payform" name="payform" method="post">
                <input type="hidden" name="PayMethod" value="CARD" placeholder="" />
                <input type="hidden" name="GoodsCnt" maxLength="2" value="1" placeholder="" />
                <input type="hidden" name="GoodsName" maxLength="40" value="비회원 QR 결제" placeholder="" />
                <input
                  type="hidden"
                  name="Amt"
                  maxLength="12"
                  value={kwhPay - Number(Math.floor(kwhPay - kwhPay / 1.1)) + Number(Math.floor(kwhPay - kwhPay / 1.1)) + 0}
                  placeholder=""
                />
                <input type="hidden" name="Moid" maxLength="40" value={Moid} placeholder="특수문자 포함 불가" />
                <input type="hidden" name="Mid" maxLength="10" value={process.env.REACT_APP_SMARTRO_QR_MID} placeholder="" />
                <input
                  type="hidden"
                  name="ReturnUrl"
                  size="100"
                  className="input"
                  value={process.env.REACT_APP_API_URL + "/qrPay/payment/" + qrcode + "," + getValues("Phone")}
                />

                <input type="hidden" name="BuyerName" maxLength="30" value={Phone} placeholder="" />
                <input type="hidden" name="BuyerTel" maxLength="30" value={Phone} placeholder="" />
                <input type="hidden" name="BuyerEmail" maxLength="30" value="test@gmail.com" placeholder="" />
                <input type="hidden" name="MallIp" maxLength="20" value="10.0.0.1" placeholder="" />
                <input type="hidden" name="EncryptData" value={makePayEncryptData(EdiDate, kwhPay)} placeholder="위/변조방지 HASH 데이터" />
                <input type="hidden" name="GoodsCl" value="0" placeholder="가맹점 설정에 따라 0 또는 1, 핸드폰결제 시 필수" />
                <input type="hidden" name="EdiDate" maxLength="14" value={EdiDate} placeholder="" />
                <input
                  type="hidden"
                  name="VatAmt"
                  maxLength="12"
                  value={Number(Math.floor(kwhPay - kwhPay / 1.1))}
                  placeholder="부가세 직접계산 가맹점 필수,숫자만 가능, 문장부호 제외"
                />
                <input
                  type="hidden"
                  name="TaxAmt"
                  value={kwhPay - Number(Math.floor(kwhPay - kwhPay / 1.1))}
                  placeholder="부가세 직접계산 가맹점 필수,숫자만 가능, 문장부호 제외"
                />
                <input
                  type="hidden"
                  name="TaxFreeAmt"
                  maxLength="12"
                  value={0}
                  placeholder="부가세 직접계산 가맹점 필수,숫자만 가능, 문장부호 제외"
                />
              </Form>
              <form id="approvalForm" name="approvalForm" method="post">
                <input type="hidden" id="qrcode" name="qrcode" />
                <input type="hidden" id="Tid" name="Tid" />
                <input type="hidden" id="TrAuthKey" name="TrAuthKey" />
                <input type="hidden" id="phone" name="phone" />
                <input type="hidden" id="price" name="price" />
              </form>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

const QRpayWrap = css`
  padding: 3% 15%;
  height: 100%;
  min-height: 721px;

  input[type="checkbox"] {
    display: none;
  }

  .form-check {
    input,
    label {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .card {
    border: none;

    h1 {
      padding-bottom: 32px;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
    }

    .card-body {
      padding: 0;

      .form_row {
        display: flex;
        padding-bottom: 24px;

        .input_div {
          width: 100%;
          height: 48px;
        }

        .label_div {
          font-weight: 400;
          font-size: 16px;
          line-height: 225.3%;
          color: ${button_text};

          min-width: 115px;
        }

        .text_div {
          width: 100%;
        }

        textarea {
          border-radius: 10px;
        }

        input[type="text"] {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
`;

export default QRpayChargingInfoPage;
